<template>
  <div class="login">
    <h2>{{'auth.welcome' | translate}}</h2>
    <form @submit.prevent="oauthLogin">
      <div class="form-group">
        <div class="input-group">
          <input type="text" id="email" name="email" required="required" v-model="credentials.email"/>
          <label class="control-label" for="email">{{'auth.email' | translate}}</label><i class="bar"></i>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="input-group">
          <input type="password" id="password" name="password" required="required" v-model="credentials.password"/>
          <label class="control-label" for="password">{{'auth.password' | translate}}</label><i class="bar"></i>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between down-container">
        <router-link class="link" :to="{name: 'auth.reset-password-email'}">
          {{ 'auth.forgotPassword' | translate }}
        </router-link>
        <Button classes="btn btn-primary" :loading="loading">
          {{'auth.login' | translate}}
        </Button>
        <router-link v-if="false" class='link' :to="{name: 'signup'}">{{'auth.createAccount' | translate}}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'oauth',

  data () {
    return {
      credentials: {
        client_id: '',
        email: '',
        password: '',
      },
      query: {},
    }
  },

  mounted() {
    this.query = this.$route.query;
    this.credentials.client_id = this.query.client_id
  },

  methods: {
    oauthLogin () {
      this.$store.dispatch('auth/oauthLogin', this.credentials)
        .then((res) => {
          if (res && res.refresh_token)
          {
            let url = process.env.VUE_APP_ZAP_REDIRECT_URL
            if (window.location.host === 'app.torowave.com')
              url = process.env.VUE_APP_ZAP_REDIRECT_URL_TW
            window.location = `${url}?state=${this.$route.query.state}&code=${res.refresh_token}`
          }
        })
        .catch(() => {})
    },
  },

  computed: {
    loading () { 
      return this.$store.state.auth.loading
    },
  }
}
</script>

<style lang="scss">
  .login {
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
      .down-container {
        .link {
          margin-top: 2rem;
        }
      }
    }

    h2 {
      text-align: center;
    }
    width: 21.375rem;

    .link {
      color: #3578c6;
    }
  }
</style>
